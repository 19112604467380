.card {
  position: relative;
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  background: white;
}

.userapproval__header {
  color: white;
  text-align: center;
}

.userapproval {
  width: 90%;
  max-width: 25rem;
  margin: 2rem auto;
  text-align: center;
}

.userapproval form {
  margin-bottom: 1rem;
}

h1 {
  font-size: 24px;
  text-align: center;
  margin: 0 0 35px 0;
}


